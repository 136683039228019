body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.swal2-container.swal2-center > .swal2-popup {
  width: 25vw !important;
}
.swal2-styled.swal2-confirm {
  width: 6vw !important;
  font-size: 2em !important;
  transition: all 0.3s ease-in-out;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}
.swal2-actions {
  width: 100% !important;
}
.swal2-title {
  font-size: 3em !important;
}
.swal2-html-container {
  font-size: 1.75em !important;
}
