@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMscPq-zKlo.ttf) format('truetype');
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Baloo Tamma 2", cursive !important;
}
.App {
  text-align: center;
  font-size: 1.1em;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.border-none {
  border: none;
}
@media screen and (max-width: 767px) {
  header {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  header .banner-text h1 {
    font-size: 10vw !important;
  }
}
@media screen and (max-width: 767px) {
  header .banner-text hr {
    width: 100% !important;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  header .social {
    top: 0;
  }
}
@media screen and (max-width: 767px) {
  header .social li {
    margin: 0 1vw;
  }
}
@media screen and (max-width: 767px) {
  header .scrolldown {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  header .scrolldown i {
    position: relative;
    left: 0;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .row.status {
    width: 85%;
  }
}
@media screen and (max-width: 767px) {
  #contact {
    padding-bottom: 5vw;
  }
}
@media screen and (max-width: 767px) {
  #contact ul {
    width: 85%;
  }
}
@media screen and (max-width: 767px) {
  #contact h2.cl-white {
    padding: 1vw 0;
  }
}
@media screen and (max-width: 767px) {
  #contact form {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  footer .social-links {
    top: 0;
  }
}
@media screen and (max-width: 767px) {
  footer .social-links li {
    margin: 0 1vw;
  }
}
@media screen and (max-width: 767px) {
  footer {
    padding-top: 5vw;
  }
}
footer #go-top {
  position: relative;
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
  left: 0;
}
footer #go-top a {
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  footer #go-top a {
    width: auto;
    height: auto;
    line-height: normal;
  }
}
.responsive-headline {
  font: 60px/1.1em "Monoton", cursive !important;
  letter-spacing: 1px;
}
.summary {
  text-align: left;
}
header .banner-text hr {
  width: 70%;
}
header .scrolldown i {
  bottom: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
